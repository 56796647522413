import React from "react";
import PropTypes from "prop-types";
import interTech from "../images/inter-tech.png";
import teaapl from "../images/teaapl.png";
import github from "../images/github.png";
import DiTe from "../images/DiTe.png";
import VideoCall from "../images/Video_Call_App.png";
import georgethecoder from "..//images/georgethecoderdotcom.png";
import { memo } from "react";
import { Helmet } from "react-helmet";
import Logo from "../images/georgethecoder_p.png";
import mysecondfather from "../images/patervasileiostsiounis.png";
import Snowfall from "react-snowfall";

// Project data
const projects = [
  {
    title: "Inter Tech - SoftBiz Rights",
    description: "Website for construction company",
    imageUrl: interTech,
    technologies: ["HTML5", "Tailwind", "Javascript", "JQuery"],
    url: "https://inter-tech.gr/",
  },
  {
    title: "Teaapl - SoftBiz Rights",
    description: "Website for professional body insurance fund",
    imageUrl: teaapl,
    technologies: ["React.js", "Tailwind", "Strapi"],
    url: "https://georgethecoderdot.github.io/teaapl/",
  },
  {
    title: "Prime Factorization TCP Program",
    description: "Program about a network client-server application.",
    imageUrl: github,
    technologies: ["C"],
    url: "https://github.com/georgethecoderdot/TCP-Server-Client-Program-for-Prime-Factorization-of-Positive-Integers-in-C",
  },
  {
    title: "Database App with Triggers",
    description:
      "Program that allows the user to interact with a MySQL database",
    imageUrl: github,
    technologies: ["Python", "MySQL"],
    url: "https://github.com/georgethecoderdot/Database-Application-with-Transaction-Management-and-Triggers.",
  },
  {
    title: "DiTe - SoftBiz Rights",
    description: "Variant of the website Inter-Tech",
    imageUrl: DiTe,
    technologies: ["HTML5", "Tailwind", "Javascript", "JQuery"],
    url: "https://georgethecoderdot.github.io/DiTe/",
  },
  {
    title: "www.georgethecoder.com",
    description: "My Personal Site",
    imageUrl: georgethecoder,
    technologies: ["React.js", "Tailwind", "Strapi", "AWS"],
    url: "https://www.georgethecoder.com",
  },
  {
    title: "Video-Call App - SoftBiz Rights",
    description: "Main interface of the video-call App",
    imageUrl: VideoCall,
    technologies: ["React.js", "Tailwind"],
    url: "https://georgethecoderdot.github.io/video-call-app/",
  },
  {
    title: "www.patervasileiostsiounis.com",
    description: "Tribute to my late grandfather",
    imageUrl: mysecondfather,
    technologies: ["React.js", "Tailwind"],
    url: "https://www.patervasileiostsiounis.com",
  },
];

const isSnowSeason = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  // Create the start and end dates using MM/DD/YYYY format
  const startSnowDate = new Date(`${year}-12-01`); // December 1st of the current year
  const endSnowDate = new Date(`${year}-01-07`); // January 7th of the next year

  // Check if current date is within the snow season
  return currentDate >= startSnowDate || currentDate <= endSnowDate;
};

// Individual project card component
const ProjectCard = memo(({ project }) => {
  return (
    <>
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      <Helmet>
        <title>Projects - diakos.georgethecoder.com</title>
        <link rel="icon" href={Logo} type="image/png" />
      </Helmet>
      <a
        href={project.url}
        target="_blank"
        rel="noopener noreferrer"
        className="group relative overflow-hidden rounded-lg transition-all duration-500 ease-in-out transform hover:scale-105 hover:shadow-2xl bg-white"
      >
        <div className="aspect-w-16 aspect-h-9 overflow-hidden">
          <img
            src={project.imageUrl}
            alt={project.title}
            className="object-cover w-full h-full transition-transform duration-700 ease-in-out group-hover:scale-110"
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-gray-800 to-black opacity-70 group-hover:opacity-80 transition-opacity duration-500 ease-in-out">
          <div className="absolute bottom-0 left-0 p-4">
            <h3 className="text-white text-xl font-semibold truncate transition duration-300 ease-in-out group-hover:text-xl">
              {project.title}
            </h3>
            <p className="text-gray-300 text-sm mt-1 hidden md:block">
              {project.description}
            </p>
          </div>
          <div className="flex items-center justify-start space-x-2 p-4">
            {project.technologies.map((tech, index) => (
              <span
                key={index}
                className="text-white text-xs font-semibold px-2 py-1 bg-blue-600 bg-opacity-80 rounded-full hover:bg-blue-700 transition duration-300"
              >
                {tech}
              </span>
            ))}
          </div>
        </div>
      </a>
    </>
  );
});
ProjectCard.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    technologies: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

// Grid component to hold project cards
const ProjectsGrid = memo(({ projects }) => {
  return (
    // Adjust grid layout for different screen sizes
    <div className="grid grid-cols-1 gap-2 px-4 md:grid-cols-2 lg:grid-cols-3 md:gap-4 md:px-0">
      {projects.map((project, index) => (
        <ProjectCard key={project.title + index} project={project} />
      ))}
    </div>
  );
});

ProjectsGrid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Main Projects component
const Projects = () => {
  return (
    <div className="container mx-auto p-4 md:p-6 lg:p-8">
      <h2 className="text-2xl md:text-3xl lg:text-6xl font-extrabold leading-snug mb-6 md:mb-8 lg:mb-10 tracking-tighter text-custom-new-blue serif-font mt-4 sm:mt-0">
        Projects
      </h2>
      <div className="flex justify-center">
        <div className="w-full max-w-6xl">
          <ProjectsGrid projects={projects} />
        </div>
      </div>
    </div>
  );
};

export default Projects;
