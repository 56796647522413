import React, { useState } from "react";
import george from "../images/george.png";
import { Link } from "react-router-dom";
import Snowfall from "react-snowfall";
import { useNavigate } from "react-router-dom";

const NAV_ITEMS = [
  { label: "Home", link: "/#Home", isExternal: true },
  { label: "About Me", link: "/#About", isExternal: false },
  { label: "Tech Stack", link: "/#Tech", isExternal: false },
  { label: "Projects", link: "/projects", isExternal: true },
  { label: "Contact", link: "/#communicationForm", isExternal: false },
  // { label: "FAQs", link: "/FAQs", isExternal: true },
];

const USER_INFO = {
  name: "George Diakos",
  role: "Front-End Developer",
  email: "georgethecoder@yahoo.com",
  avatar: george,
};

const LeftWrapperBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  // Function to handle closing the sidebar

  const handleNavLinkClick = (isExternal, link, event) => {
    if (!isExternal) {
      event.preventDefault();

      if (link === "/" || link === "/#Home") {
        // If already on the homepage, simply scroll to the top
        if (window.location.pathname === "/") {
          window.scrollTo(0, 0);
        } else {
          // If not on the homepage, navigate to the homepage and then scroll to the top
          navigate("/", { replace: true });
          setTimeout(() => window.scrollTo(0, 0), 0);
        }
      } else {
        // Handling other links
        const sectionId = link.split("/#")[1];
        if (window.location.pathname !== "/") {
          navigate("/", { replace: true });
          setTimeout(() => scrollToSection(sectionId), 0);
        } else {
          scrollToSection(sectionId);
        }
      }
    }
    setIsSidebarOpen(false);
  };

  // Function to scroll to a specific section
  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const HamburgerIcon = ({ isOpen }) => (
    <div className={`hamburger-menu ${isOpen ? "open" : ""}`}>
      <span
        className={`hamburger-line top-line ${isOpen ? "open" : ""}`}
      ></span>
      <span
        className={`hamburger-line middle-line ${isOpen ? "open" : ""}`}
      ></span>
      <span
        className={`hamburger-line bottom-line ${isOpen ? "open" : ""}`}
      ></span>
    </div>
  );

  const NavBar = ({ isSidebarOpen, setIsSidebarOpen }) => (
    <div className="fixed top-0 left-0 right-0 z-30 flex justify-between items-center p-4 bg-custom-blue md:hidden">
      {/* Hamburger button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="flex justify-center items-center p-2 mb-2"
        aria-label={isSidebarOpen ? "Close Menu" : "Open Menu"}
      >
        <HamburgerIcon isOpen={isSidebarOpen} />
      </button>

      {/* "Keep Coding" text at the far right */}
      <div className="text-white text-sm font-bold float ">
        Keep Coding{" "}
        <span role="img" aria-label="rocket" className="inline-block ml-2">
          🚀
        </span>
      </div>
    </div>
  );

  const isSnowSeason = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // Create the start and end dates using MM/DD/YYYY format
    const startSnowDate = new Date(`${year}-12-01`); // December 1st of the current year
    const endSnowDate = new Date(`${year}-01-07`); // January 7th of the next year

    // Check if current date is within the snow season
    return currentDate >= startSnowDate || currentDate <= endSnowDate;
  };

  const SidebarContent = () => (
    <div className="p-2 text-center ">
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      {/* This img tag will be hidden on screens smaller than 'md' */}
      <img
        src={USER_INFO.avatar}
        alt="User Avatar"
        className="hidden md:block mx-auto w-32 h-32 rounded-full border-4 border-white shadow-lg mb-4"
      />
      <nav>
        <ul className="pt-12 font-bold">
          {NAV_ITEMS.map((item) => (
            <li
              key={item.label}
              className="text-left lg:pl-32 pl-16 mb-2 p-2 rounded text-3xl"
            >
              {item.isExternal ? (
                <Link
                  to={item.link}
                  className="text-white hover-bg-effect rounded transition duration-300"
                  data-text={item.label}
                  onClick={(e) =>
                    handleNavLinkClick(item.isExternal, item.link, e)
                  }
                >
                  {item.label}
                </Link>
              ) : (
                <a
                  href={item.link}
                  onClick={(e) =>
                    handleNavLinkClick(item.isExternal, item.link, e)
                  }
                  className="text-white hover-bg-effect rounded transition duration-300"
                  data-text={item.label}
                >
                  {item.label}
                </a>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="pt-12">
        <InfoItem label="Name" value={USER_INFO.name} />
        <InfoItem label="Role" value={USER_INFO.role} />
        <InfoItem label="Email" value={USER_INFO.email} />
      </div>
    </div>
  );

  return (
    <div
      className={`relative h-screen bg-custom-blue text-white md:w-80 lg:w-96`}
    >
      <NavBar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <div
        className={`fixed inset-y-0 left-0 z-20 w-64 bg-custom-blue transform transition-transform duration-1000 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <SidebarContent />
      </div>

      {/* For desktop */}
      <div className="hidden md:block">
        <SidebarContent />
      </div>
    </div>
  );
};
const InfoItem = ({ label, value }) => (
  <>
    <div className="text-blue-500 mb-2">{label}</div>
    <div className="text-white mb-4">{value}</div>
  </>
);

export default LeftWrapperBar;
