import React from "react";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Snowfall from "react-snowfall";
import js from "../images/js_logo.png";
import jquery from "../images/JQuery-logo.png";
import react from "../images/react_JS_logo.png";
import Tailwind from "../images/Tailwind_CSS.png";
import HTML5 from "../images/html-logo.png";
import CSS3 from "../images/css-logo.png";
import Java from "../images/java_orig_logo.png";
import git from "../images/git.png";
import py from "../images/python-language-logo.png";
import strapi from "../images/strapi.png";
import MySQL from "../images/MySQL.png";

const techStack = [
  {
    name: "HTML5",
    imgSrc: HTML5,
  },
  {
    name: "CSS3",
    imgSrc: CSS3,
  },
  {
    name: "Tailwind",
    imgSrc: Tailwind,
  },
  {
    name: "Javascript",
    imgSrc: js,
  },
  {
    name: "React.js",
    imgSrc: react,
  },
  {
    name: "Java",
    imgSrc: Java,
  },
  {
    name: "Python",
    imgSrc: py,
  },
  {
    name: "Jquery",
    imgSrc: jquery,
  },
  {
    name: "MySQL",
    imgSrc: MySQL,
  },
  {
    name: "Git",
    imgSrc: git,
  },
  {
    name: "Strapi",
    imgSrc: strapi,
  },
];

// Add this function to check if the current date is within the snow season
const isSnowSeason = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  // Create the start and end dates using MM/DD/YYYY format
  const startSnowDate = new Date(`${year}-12-01`); // December 1st of the current year
  const endSnowDate = new Date(`${year}-01-07`); // January 7th of the next year

  // Check if current date is within the snow season
  return currentDate >= startSnowDate || currentDate <= endSnowDate;
};

const Tech = () => {
  const [isVisible, setIsVisible] = useState(false);
  const techRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          // We can disconnect after the section is visible once to avoid further observations
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1, // The callback will run when at least 10% of the target is visible
      }
    );

    if (techRef.current) {
      observer.observe(techRef.current);
    }

    return () => {
      if (techRef.current) {
        observer.unobserve(techRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={techRef}
      className="relative flex-1 flex flex-col justify-center p-4 md:p-12 lg:p-24 space-y-6 md:space-y-10 lg:space-y-14 z-10 bg-custom-blue"
    >
      {isSnowSeason() && <Snowfall snowflakeCount={50} />}
      {/* CSS for animations */}
      <style jsx>{`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-5px);
          }
          60% {
            transform: translateY(-3px);
          }
        }
      `}</style>

      <h1
        id="Tech"
        className="text-2xl md:text-4xl lg:text-6xl font-extrabold leading-snug mb-3 md:mb-5 lg:mb-7 tracking-tighter text-custom-new-blue serif-font"
      >
        Tech Stack
      </h1>
      <p className="text-md md:text-lg lg:text-xl text-white mb-6">
        This is the collection of technologies, programming languages, and
        frameworks I frequently utilize in my daily projects.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {techStack.map((tech, index) => (
          <div
            key={index}
            className={`flex flex-col items-center space-y-2 p-4 hover:scale-105 transform transition-transform duration-300 hover:bg-custom-hover-blue rounded-md ${
              isVisible
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-20"
            } transition-all duration-500 delay-${index * 100} animation: ${
              isVisible ? "rotate 5s, bounce 5s 5s" : "none"
            }`}
          >
            <div
              className="p-6 rounded-full"
              style={{ backgroundColor: tech.bgColor }}
            >
              {tech.icon ? (
                <FontAwesomeIcon
                  icon={tech.icon}
                  size="2x"
                  style={{ color: tech.iconColor }}
                />
              ) : (
                <img
                  src={tech.imgSrc}
                  alt={tech.name}
                  style={{ width: "4rem", height: "4rem" }}
                />
              )}
            </div>
            <span className="text-md font-medium text-white">{tech.name}</span>
            {tech.description && (
              <span className="text-sm font-light text-white opacity-80">
                {tech.description}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tech;
