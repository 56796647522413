import React from "react";
import LeftWrapperBar from "./pages/LeftWrapper";
import Home from "./pages/Home";
import About from "./components/About";
import Tech from "./components/Tech";
import ContactForm from "./components/ContactForm";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import Error404 from "./components/Error404";
// import Faq from "./components/FAQs";

import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="flex h-screen">
        <LeftWrapperBar />
        <div className="flex-1 overflow-auto main-content bg-custom-blue flex flex-col">
          <div className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Home />
                    <About />
                    <Tech />
                    <ContactForm />
                  </>
                }
              />
              <Route path="/projects" element={<Projects />} />
              <Route path="*" Component={<Error404 />} />
              {/* <Route path="/FAQs" element={<Faq />} /> */}

              {/* Redirect any non-matching paths to Home */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
